import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'poll_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="poll_outline_32"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M16 8.088c.69 0 1.25.56 1.25 1.25v13.325a1.25 1.25 0 11-2.5 0V9.338c0-.69.56-1.25 1.25-1.25zM10.5 12.963c.69 0 1.25.56 1.25 1.25v8.45a1.25 1.25 0 11-2.5 0v-8.45c0-.69.56-1.25 1.25-1.25zM21.5 16.212c.69 0 1.25.56 1.25 1.25v5.2a1.25 1.25 0 11-2.5 0v-5.2c0-.69.56-1.25 1.25-1.25z" /><path d="M6.325 3.868C7.535 3.222 8.725 3 11.333 3h9.334c2.609 0 3.799.222 5.008.868a5.923 5.923 0 012.457 2.457c.646 1.21.868 2.4.868 5.008v9.334c0 2.609-.222 3.799-.868 5.008a5.923 5.923 0 01-2.457 2.457c-1.21.646-2.4.868-5.008.868h-9.334c-2.609 0-3.799-.222-5.008-.868a5.923 5.923 0 01-2.457-2.457c-.646-1.21-.868-2.4-.868-5.008v-9.334c0-2.609.222-3.799.868-5.008a5.923 5.923 0 012.457-2.457zM11.333 5.5c-2.479 0-3.169.22-3.829.573-.62.331-1.1.81-1.431 1.431-.353.66-.573 1.35-.573 3.829v9.334c0 2.479.22 3.168.573 3.829.331.62.81 1.1 1.431 1.431.66.353 1.35.573 3.829.573h9.334c2.479 0 3.168-.22 3.829-.573.62-.332 1.1-.81 1.431-1.431.353-.66.573-1.35.573-3.829v-9.334c0-2.479-.22-3.169-.573-3.829a3.424 3.424 0 00-1.431-1.431c-.66-.353-1.35-.573-3.829-.573z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PollOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32PollOutline: FC<Icon32PollOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32PollOutline as any).mountIcon = mountIcon;

export default Icon32PollOutline;
