import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'history_backward_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="history_backward_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M12 7.1a.9.9 0 01.9.9v3.727l2.236 2.237a.9.9 0 11-1.272 1.272l-2.5-2.5a.9.9 0 01-.264-.636V8a.9.9 0 01.9-.9z" fill-rule="evenodd" /><path d="M19 5a9.872 9.872 0 00-7-2.9 9.873 9.873 0 00-7.1 3V3a.9.9 0 00-1.8 0v4a.9.9 0 00.9.9h4a.9.9 0 000-1.8H6.45A8.069 8.069 0 0112 3.9c2.237 0 4.26.906 5.728 2.372A8.072 8.072 0 0120.1 12c0 2.237-.906 4.26-2.372 5.728A8.072 8.072 0 0112 20.1a8.064 8.064 0 01-5.203-1.891.9.9 0 00-1.156 1.379A9.864 9.864 0 0012 21.9c2.733 0 5.21-1.109 7-2.9s2.9-4.267 2.9-7-1.109-5.21-2.9-7z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HistoryBackwardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24HistoryBackwardOutline: FC<Icon24HistoryBackwardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24HistoryBackwardOutline as any).mountIcon = mountIcon;

export default Icon24HistoryBackwardOutline;
