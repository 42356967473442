import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'star_circle_fill_yellow_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="star_circle_fill_yellow_16"><linearGradient id="star_circle_fill_yellow_16_a" gradientUnits="userSpaceOnUse" x1="-8" x2="8" y1="8" y2="24"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M8 0a8 8 0 110 16A8 8 0 018 0zm.148 3.454a.333.333 0 00-.447.152l-1.268 2.57-2.837.412a.333.333 0 00-.185.569l2.053 2-.485 2.825a.333.333 0 00.484.352L8 11l2.537 1.334a.333.333 0 00.484-.352l-.485-2.825 2.053-2a.334.334 0 00-.185-.569l-2.837-.412L8.3 3.606a.333.333 0 00-.151-.152z" fill="url(#star_circle_fill_yellow_16_a)" /><path d="M8.148 3.454a.333.333 0 00-.447.152l-1.268 2.57-2.837.412a.333.333 0 00-.185.569l2.053 2-.485 2.825a.333.333 0 00.484.352L8 11l2.537 1.334a.333.333 0 00.484-.352l-.485-2.825 2.053-2a.333.333 0 00-.185-.569l-2.837-.412L8.3 3.606a.333.333 0 00-.151-.152z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16StarCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16StarCircleFillYellow: FC<Icon16StarCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16StarCircleFillYellow as any).mountIcon = mountIcon;

export default Icon16StarCircleFillYellow;
