import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'services_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="services_circle_fill_blue_20"><linearGradient id="services_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#services_circle_fill_blue_20_a)" /><path d="M7.648 10.75c.557 0 .759.058.963.167.203.109.363.269.472.472s.167.406.167.963v1.046c0 .557-.058.759-.167.962s-.269.364-.472.473c-.204.109-.406.167-.963.167H6.602c-.557 0-.759-.058-.963-.167-.203-.109-.363-.269-.472-.473S5 13.955 5 13.398v-1.046c0-.557.058-.759.167-.963s.269-.363.472-.472c.204-.109.406-.167.963-.167zm5.75 0c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v1.046c0 .557-.058.759-.167.962a1.135 1.135 0 01-.472.473c-.204.109-.406.167-.963.167h-1.046c-.557 0-.759-.058-.962-.167a1.135 1.135 0 01-.473-.473c-.109-.203-.167-.405-.167-.962v-1.046c0-.557.058-.759.167-.963.109-.203.269-.363.473-.472.203-.109.405-.167.962-.167zm-.189-6.06c.221.067.405.169.8.563l.738.739c.394.394.496.578.563.799s.067.447 0 .668c-.067.221-.169.405-.563.8l-.739.738c-.394.394-.578.496-.799.563s-.447.067-.668 0-.405-.169-.8-.563l-.738-.739c-.394-.394-.496-.578-.563-.799-.067-.221-.067-.447 0-.668s.169-.405.563-.8l.739-.738c.394-.394.578-.496.799-.563.221-.067.447-.067.668 0zM7.648 5c.557 0 .759.058.963.167.203.109.363.269.472.472s.167.406.167.963v1.046c0 .557-.058.759-.167.963-.109.203-.269.363-.472.472s-.406.167-.963.167H6.602c-.557 0-.759-.058-.963-.167a1.136 1.136 0 01-.472-.472C5.058 8.407 5 8.205 5 7.648V6.602c0-.557.058-.759.167-.963s.269-.363.472-.472c.204-.109.406-.167.963-.167z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ServicesCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ServicesCircleFillBlue: FC<Icon20ServicesCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ServicesCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20ServicesCircleFillBlue;
