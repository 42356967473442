import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'shopping_cart_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="shopping_cart_outline_24"><path clip-rule="evenodd" d="M3.493 4.934c-.085-.025-.21-.034-.834-.034H2a.9.9 0 110-1.8h.76c.463 0 .87-.001 1.235.105A2.65 2.65 0 015.654 4.62c.07.15.122.31.164.48h12.318c.43 0 .793 0 1.088.017.305.018.606.057.899.167a2.9 2.9 0 011.856 2.314 3.04 3.04 0 01-.033.913c-.047.292-.126.646-.219 1.066l-.006.026-.633 2.852-.025.112c-.14.633-.248 1.12-.467 1.53a3.4 3.4 0 01-2.093 1.68c-.449.124-.947.123-1.596.123H9.365c-.686 0-1.212 0-1.68-.135a3.4 3.4 0 01-2.127-1.816c-.207-.44-.29-.96-.397-1.637l-.02-.12-.951-5.986c-.098-.616-.127-.738-.165-.818a.85.85 0 00-.532-.454zM6.123 6.9l.797 5.008c.136.856.183 1.097.266 1.274a1.6 1.6 0 001.002.854c.188.055.433.064 1.3.064h7.304c.818 0 1.05-.008 1.23-.058a1.6 1.6 0 00.985-.79c.088-.166.146-.39.324-1.188l.633-2.852c.1-.453.168-.754.205-.988.037-.23.034-.327.027-.377a1.1 1.1 0 00-.704-.877c-.047-.018-.141-.042-.374-.056A19.226 19.226 0 0018.11 6.9zM7 19.25a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zm10.25-1.75a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ShoppingCartOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ShoppingCartOutline: FC<Icon24ShoppingCartOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ShoppingCartOutline as any).mountIcon = mountIcon;

export default Icon24ShoppingCartOutline;
